<script setup>
import Settings from '@/models/Settings'
import {Feedback, arrayBufferToBase64} from 'manager'
import {onMounted, reactive} from 'vue'
const emit = defineEmits(['input'])

const page = reactive({
    cert: [],
    key: [],
    saving: null,
})

onMounted(async () => {})

async function save() {
    if (!page.key[0] || !page.cert[0]) {
        Feedback.error('Missing key or certificate')
        return
    }
    let key = await readFile(page.key[0])
    let cert = await readFile(page.cert[0])
    await Settings.uploadCert({key, cert})
    emit('input')
}

async function readFile(file) {
    let reader = new FileReader()
    reader.readAsArrayBuffer(file)
    let data = await new Promise((resolve, reject) => {
        reader.onload = async () => {
            try {
                resolve(reader.result)
            } catch (err) {
                Feedback.error(err.message)
                reject(err)
            }
        }
    })
    return {name: file.name, size: file.size, data: arrayBufferToBase64(data)}
}
</script>

<template>
    <vu-form :data="page" autocomplete="off" :save="save" class="page-form">
        <h1>Upload TLS Certificates</h1>
        <v-file-input :clearable="true" v-model="page.cert" label="Attach certificate..." class="vcol-12" />
        <v-file-input :clearable="true" v-model="page.key" label="Attach key file ..." class="vcol-12" />
        <div class="actions">
            <v-btn small color="accent" type="submit" v-if="page.cert[0]?.name && page.key[0]?.name" :loading="page.saving">
                Save
            </v-btn>
            <v-btn small @click="emit('input')">Cancel</v-btn>
        </div>
    </vu-form>
</template>

<style lang="scss" scoped>
.settings-form {
    margin: 40px 0 0 40px;
    width: 800px;
}
</style>
