<template>
    <div class="vlans">
        <vu-nav title="VLANs">
            <v-tab to="/vlans/list">List</v-tab>
        </vu-nav>
        <v-container>
            <router-view />
        </v-container>
    </div>
</template>
