<script setup>
/*
    Add a port to a VLAN
*/
import {onMounted, reactive} from 'vue'
import {Port, Vlan} from '@/models'

const props = defineProps({
    id: String,
    vlanId: String,
})

const emit = defineEmits(['input'])

const page = reactive({
    port: {},
    saving: null,
    vlanId: null,
    vlans: [],
})

onMounted(async () => {
    page.port = await Port.get({id: props.id})
    if (props.vlanId != null) {
        page.vlanId = props.vlanId
    }
    page.vlans = await Vlan.find()
})

async function save() {
    await Vlan.addPort({id: page.vlanId, portId: page.port.id})
    emit('input')
}

async function remove() {
    await Vlan.removePort({id: page.vlanId, portId: page.port.id})
    emit('input')
}
</script>

<template>
    <vu-form :data="page" autocomplete="off" title="Add Port to VLAN" :save="save">
        <v-select v-model="page.vlanId" :items="page.vlans" item-title="name" item-value="id" name="vlan" label="Select VLAN" />
        <div class="actions">
            <v-btn small color="accent" type="submit" :loading="page.saving">Add</v-btn>
            <v-btn small @click="emit('input')">Cancel</v-btn>
            <v-btn small color="error" @click="remove">Remove from VLAN</v-btn>
        </div>
    </vu-form>
</template>
