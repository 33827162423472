/*
    Application entity models for communicating with the server using REST APIs.
*/
import {Models} from 'manager'
import Event from './Event.js'
import Mapping from './Mapping.js'
import Port from './Port.js'
import Settings from './Settings.js'
import Vlan from './Vlan.js'

export { Event, Mapping, Port, Settings, Vlan }
export const
    Account = Models.Account,
    Dashboard = Models.Dashboard,
    Device = Models.Device, 
    Generic = Models.Generic,
    Metric = Models.Metric,
    User = Models.User
