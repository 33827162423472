/*
    Mapping.js - Mapping model
 */

import {Rest, State} from 'manager'

export default new Rest('mapping', {}, {
    //  Tunnel when used by CloudKick
    tunnel: 'Mapping',
    context: (body) => {
        if (State.config.name == 'CloudKick') {
            body.deviceId = State.app.context.deviceId
        }
        return body
    }
})
