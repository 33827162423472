/*
    Settings.js - Settings singleton model
 */

import {Rest, State} from 'manager'

export default new Rest('settings', {
    reset:      { method: 'POST', uri: '/:controller/reset' },
    uploadCert: { method: 'POST', uri: '/:controller/uploadCert' },
}, {
    //  Tunnel when used by CloudKick
    tunnel: 'Settings',
    context: (body) => {
        if (State.config.name == 'CloudKick') {
            body.deviceId = State.app.context.deviceId
        }
        return body
    }
})
