<script setup>
/*
    Display a list of users
*/
import {onMounted, reactive} from 'vue'
import {User} from '@/models'
import UserEdit from './UserEdit.vue'

const Fields = [
    { name: 'edit', icon: '$edit'},
    {name: 'username'},
    {name: 'role'},
]

const page = reactive({
    user: {},
    users: null,
    showEdit: false,
})

onMounted(async () => {
    await renderUsers()
})

async function renderUsers() {
    let users = (page.users = await User.find())
    if (users) {
        for (let user of users) {
            user.roles = user.roles || 'user'
            let roles = user.roles.split(',')
            if (roles.indexOf('super') >= 0) {
                user.role = 'Super'
            } else if (roles.indexOf('admin') >= 0) {
                user.role = 'Admin'
            } else {
                user.role = 'User'
            }
        }
    }
}

async function clicked({action, item, items}) {
    if (action == 'cell' || action == 'edit') {
        editUser(item)
    } else if (action == 'add') {
        editUser({})
    } else if (action == 'delete') {
        for (let rec of items) {
            await User.remove({id: rec.id})
        }
        await renderUsers()
    }
}

async function editUser(user) {
    page.showEdit = user ? true : false
    page.user = user || {}
    await renderUsers()
}
</script>

<template>
    <div class="user-list">
        <vu-table
            v-if="page.users"
            drag="move"
            name="user-list"
            sort="username:asc"
            options="filter,toolbar"
            width="100%"
            widths="5% 5%"
            :data="page.users"
            :select="{actions: {Add: 0, Edit: 1, Delete: 2}, multi: true, property: 'select'}"
            :fields="Fields"
            @click="clicked">
            <template v-slot:more="props">
                <v-btn color="accent" @click="editUser({})">Add User</v-btn>
            </template>
        </vu-table>
        <vu-panel v-model="page.showEdit" @close="editUser">
            <UserEdit :id="page.user.id" @input="editUser" />
        </vu-panel>
    </div>
</template>
