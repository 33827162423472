<script setup>
/*
    AdminTableList -- View a list of database models (tables)
*/
import {onMounted, reactive} from 'vue'
import {State} from 'manager'
import AdminModel from './AdminModel.vue'

const page = reactive({
    model: {},
    models: null,
    showModel: false,
})

const Fields = [
    {name: 'edit', icon: '$edit', width: '5%'},
    {name: 'name', align: 'left'},
    {name: 'enable'},
    {name: 'metrics'},
    {name: 'sync'},
    {name: 'notify'},
    {name: 'period'},
    {name: 'show'},
]
onMounted(async () => {
    await renderModels()
})

function renderModels() {
    page.models = []
    for (let [name, model] of Object.entries(State.app.schema.models)) {
        let item = {name}
        let process = State.app.schema.process[name]
        if (process) {
            Object.assign(item, process)
        }
        page.models.push(item)
    }
}

function clicked(params) {
    let {action, item} = params
    if (action == 'cell' || action == 'edit') {
        page.showModel = true
        editModel(item)
    }
}

async function editModel(model) {
    page.showModel = model ? true : false
    page.model = model || {}
    await renderModels()
}
</script>

<template>
    <div class="page admin-models">
        <vu-table
            options="filter,toolbar"
            name="admin-models"
            :data="page.models"
            :fields="Fields"
            :select="{actions: {Edit: 1}, multi: false, property: 'select'}"
            :pageSize="20"
            @click="clicked" />

        <vu-panel v-model="page.showModel" @close="editModel" :widths="['900px']">
            <AdminModel :name="page.model.name" @input="editModel" />
        </vu-panel>
    </div>
</template>
