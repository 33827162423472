<template>
    <div class="users">
        <vu-nav title="Users">
            <v-tab to="/users/list">List</v-tab>
        </vu-nav>
        <v-container>
            <router-view />
        </v-container>
    </div>
</template>
