<script setup>
import {onMounted, reactive} from 'vue'
import {State} from 'manager'

const props = defineProps({
    name: String,
})

const page = reactive({
    fields: [],
})

onMounted(async () => {
    let fields = []
    for (let [name, item] of Object.entries(State.app.schema.models[props.name])) {
        //  To get name first
        let field = {name}
        fields.push(Object.assign(field, item))
    }
    page.fields = fields
})
</script>

<template>
    <div class="admin-tables">
        <vu-table :title="props.name" :data="page.fields" drag="" sort="name:asc" options="dynamic, filter" />
    </div>
</template>

<style lang="scss">
.admin-tables {
    padding: 16px;
}
</style>
