<script setup>
/*
    EventEdit -- Display a single event
*/
import {onBeforeMount, reactive} from 'vue'
import {Event} from '@/models'
import {Dates, Feedback, State} from 'manager'

const props = defineProps({
    id: String,
})

const emit = defineEmits(['input'])

const page = reactive({
    event: {severity: 'info'},
})

onBeforeMount(async () => {
    page.event = await Event.get({id: props.id})
    if (page.event) {
        page.event.date = Dates.format(page.event.date, State.config.formats.datetime)
    }
})

async function remove() {
    await Event.remove({id: props.id})
    Feedback.info('Event Removed')
    emit('input')
}

async function save() {
    emit('input')
}

function getIcon() {
    return `$${page.event.severity}`
}
</script>

<template>
    <vu-form title="Event Details" autocomplete="off" :save="save">
        <div class="headline my-4">
            <v-icon :icon="getIcon()" :class="`severity-${page.event.severity}`" />
            {{ page.event.severity }}
            {{ page.event.subject }}
        </div>
        <p class="vcol-12 subtitle">{{ page.event.date }}</p>
        <p class="vcol-12 message">{{ page.event.message }}</p>
        <div class="actions">
            <v-btn small color="accent" type="submit" class="mr-2">Continue</v-btn>
            <v-btn small red type="error" @click.stop="remove">Delete</v-btn>
        </div>
    </vu-form>
</template>

<style lang="scss" scoped>
h1 {
    padding-bottom: 10px;
}
.v-icon {
    padding: 4px;
    background: white;
    font-size: 48px !important;
    &.severity-info {
        color: green;
    }
    &.severity-warn {
        color: orange;
    }
    &.severity-error {
        color: red;
    }
    &.severity-critical {
        color: red;
    }
}
</style>
