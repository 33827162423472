/*
    Port.js - Port model
 */

import {Rest, State} from 'manager'
import {Mapping, Vlan} from './index.js'

export default new Rest('port', {
    mappings: { invoke: async (fields) => {
        let mappings = await Mapping.find({portId: fields.id})
        let vlans = []
        for (let mapping of mappings) {
            let vlan = await Vlan.get({id: mapping.vlanId})
            vlans.push(vlan)
        }
        return vlans
    }},

}, {
    //  Tunnel when used by CloudKick
    tunnel: 'Port',
    context: (body) => {
        if (State.config.name == 'CloudKick') {
            body.deviceId = State.app.context.deviceId
        }
        return body
    }
})
