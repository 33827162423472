<script setup>
/*
    Display a list of ports
*/
import {onMounted, reactive} from 'vue'
import {Mapping, Port} from '@/models'
import PortEdit from './PortEdit.vue'

const Fields = [
    {name: 'edit', icon: '$edit', width: '5%'},
    {name: 'name', align: 'center'},
    {name: 'status', icon: { online: {name: '$online', color: 'green'}, offline: '$offline'}},
    {name: 'negotiate', align: 'center', icon: 'check' },
    {name: 'duplex', align: 'center', icon: 'check' },
    {name: 'flowControl', align: 'center', icon: 'check' },
    {name: 'jumbo', align: 'center', icon: 'check' },
    {name: 'speed', align: 'center'},
    {name: 'vlans', align: 'center'},
]

const emit = defineEmits(['input'])

const page = reactive({
    port: {},
    ports: null,
    showPortEdit: false,
})
onMounted(async () => {
    await renderPorts()
})

async function renderPorts() {
    let ports = await Port.find()
    let mappings = await Mapping.find()
    for (let port of ports) {
        let count = mappings.reduce((total, map) => {
            return map.portId == port.id ? total + 1 : total
        }, 0)
        port.vlans = count
    }
    page.ports = ports
}

async function clicked({action, item, items}) {
    if (action == 'cell' || action == 'edit') {
        editPort(item)
    } else if (action == 'delete') {
        for (let rec of items) {
            await Port.remove({id: rec.id})
        }
        await renderPorts()
    }
}

async function editPort(port) {
    page.showPortEdit = port ? true : false
    page.port = port || {}
    await renderPorts()
}
</script>

<template>
    <div class="page port-list">
        <vu-table
            name="port-table"
            title="Ports"
            sort="name:asc"
            :data="page.ports"
            :fields="Fields"
            :select="{actions: {Add: 0, Edit: 1}, property: 'select'}"
            @click="clicked" />
        <vu-panel v-model="page.showPortEdit" @close="editPort">
            <PortEdit :id="page.port.id" @input="editPort" />
        </vu-panel>
    </div>
</template>

<style lang="scss">
.port-list {
    td {
        .v-icon {
            font-size: 16px !important;
        }
        &.type-online .v-icon {
            color: green;
        }
        &.type-offline .v-icon {
            color: red;
        }
    }
}
</style>
