<script setup>
import Settings from '@/models/Settings'
import {onMounted, reactive} from 'vue'

const emit = defineEmits(['input'])
const page = reactive({
    saving: null,
})

onMounted(async () => {})

async function save(validate) {
    if (!(await confirm.ask(`Do you want to reset fo factory defaults?`, {title: 'Reset'}))) {
        return
    }
    try {
        await Settings.reset()
        //  Do a local logout only as the server is rebooting
        state.auth.updateLogin()
        this.navigate('/auth/login')
    } catch (err) {
        await validate.exception(this, err)
    }
}
</script>

<template>
    <vu-form :data="page" autocomplete="off" class="settings-form" title="Reset to Factory Defaults" :save="save">
        <div class="actions">
            <v-btn small @click="emit('input')">Cancel</v-btn>
            <v-btn small color="error" type="submit">Reset to Factory Defaults</v-btn>
        </div>
    </vu-form>
</template>

<style lang="scss" scoped>
.settings-reset {
    margin: 40px 0 0 40px;
    width: 800px;
}
.vu-input {
}
</style>
