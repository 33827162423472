<script setup>
import Settings from '@/models/Settings'
import {onMounted, reactive, ref} from 'vue'
import {Feedback, navigate} from 'manager'

const emit = defineEmits(['input'])

const Combo = ['yellow', 'black', 'red', 'white', 'blue', 'green']
const Labels = ref({
    numeric: 'Numeric',
    truthy: 'Boolean Value',
    timestamp: 'Date Time',
})
const Select = [
    {text: '1000', value: '1000'},
    {text: '10000', value: '10000'},
    {text: '40000', value: '40000'},
]
const Radio = {
    Online: 'Online',
    Offline: 'Offline',
}
const page = reactive({
    data: {
        label: 'label-value',
        date: new Date(),
        text: 'Hello World',
        check: true,
        slider: 50,
        combo: ['red, blue, green'],
    },
    saving: null,
    schema: {},
    settings: null,
    uploading: false,
})

onMounted(async () => {
    page.schema = Manager.app.schema.models.Settings
    page.settings = await Settings.get()
    page.settings.timestamp = new Date()
})

async function save() {
    await Settings.update(page.settings)
    emit('input')
}

async function reset() {
    if (!(await confirm.ask(`Do you want to reset fo factory defaults?`, {title: 'Reset'}))) {
        return
    }
    try {
        await Settings.reset()
        //  Do a local logout only as the server is rebooting
        state.auth.updateLogin()
        navigate('/auth/login')
    } catch (err) {
        await Feedback.error(err.message)
    }
}
</script>

<template>
    <vu-form :data="page" autocomplete="off" class="settings-form" title="Schema Driven" :save="save">
        <vu-input-group
            v-if="page.settings"
            v-model="page.settings"
            fields="numeric, truthy, timestamp, literal"
            :labels="Labels"
            :schema="page.schema"
            cols="12" />

        <h1 class="vcol-12">Not Schema Driven</h1>
        <vu-input v-model="page.data.label" name="Input Label" type="label" cols="12" />
        <vu-input v-model="page.data.date" name="Input Date" type="date" cols="12" />
        <vu-input v-model="page.data.text" name="Input Area" type="textarea" cols="12" />
        <vu-input v-model="page.data.check" name="Input Checkbox" type="checkbox" cols="12" />
        <vu-input v-model="page.data.select" name="Input Select" type="select" :options="Select" cols="12" />
        <vu-input v-model="page.data.radio" name="Input Radio" type="radio" :options="Radio" cols="12" />
        <vu-input v-model="page.data.slider" name="Input Slider" type="slider" :min="0" :max="100" cols="12" />
        <vu-input v-model="page.data.combo" name="Input Combo" type="combo" :options="Combo" cols="12" />

        <div class="actions">
            <v-btn small color="accent" type="submit">Save</v-btn>
            <v-btn small @click="emit('input')">Cancel</v-btn>
            <v-btn small color="error" @click="reset">Reset to Factory Defaults</v-btn>
        </div>
        <vu-confirm ref="confirm" />
    </vu-form>
</template>

<style lang="scss" scoped>
.settings-form {
    _margin: 40px 0 0 40px;
    width: 800px;
}
</style>
