<script setup>
/*
    EventList -- View a list of device events
*/
import {onMounted, reactive} from 'vue'
import {Dates, State, query} from 'manager'
import {Event} from '@/models'
import EventEdit from './EventEdit.vue'

const Fields = [
    {name: 'edit', icon: '$edit'},
    {name: 'timestamp', width: '5%', format: (v) => Dates.format(v, State.config.formats.time)},
    {name: 'severity', icon: {
        info:   {name: '$info', color: 'green'},
        warn:   {name: '$warn', color: 'orange'},
        error:  {name: '$error', color: 'red'},
        critical: {name: '$critical', color: 'red'}
    }},
    {name: 'source'},
    {name: 'subject', align: 'left'},
    {name: 'message'},
]
const emit = defineEmits(['input'])

const page = reactive({
    event: {},
    events: null,
    next: null,
    showEdit: false,
})

// const route = getRoute()

onMounted(async () => {
    await renderEvents()
    if (query('event')) {
        page.event = await Event.get({id: query('event')})
        editEvent(page.event)
    }
})

async function renderEvents() {
    let options = {}
    page.events = await Event.find(null, {limit: options.limit, next: options.offset > 0 ? page.next : null})
}

//  MOB - not used
async function getData(options) {
    let data = await Event.find(null, {limit: options.limit, next: options.offset > 0 ? page.next : null})
    page.next = (data[data.length - 1] || {}).sk + ' '
    return data
}

async function clicked({action, column, item, items}) {
    if (action == 'cell' || action == 'edit') {
        editEvent(item)
    } else if (action == 'delete') {
        for (let item of items) {
            await Event.remove({id: item.id})
        }
        await renderEvents()
    }
}

async function editEvent(event) {
    page.showEdit = event ? true : false
    page.event = event || {}
    await renderEvents()
}
</script>

<template>
    <div class="page events">
        <vu-table
            name="event-list"
            title="Events"
            options="dynamic,filter,toolbar"
            sort="true"
            :data="page.events"
            :fields="Fields"
            :select="{actions: {Edit: 1, Delete: 2}, multi: true, property: 'select'}"
            @click="clicked" />

        <vu-panel v-model="page.showEdit" @close="editEvent">
            <EventEdit :id="page.event.id" @input="editEvent" />
        </vu-panel>
    </div>
</template>
1
<style lang="scss">
.events {
    td {
        .v-icon {
            font-size: 16px !important;
        }
        &.severity-info .v-icon {
            color: green;
        }
        &.severity-warn .v-icon {
            color: orange;
        }
        &.severity-error .v-icon {
            color: red;
        }
        &.severity-critical .v-icon {
            color: red;
        }
    }
}
</style>
