<script setup>
/*
    Edit a single user
*/
import {User} from '@/models'
import {onMounted, reactive} from 'vue'
import {Feedback, State, disableAutoComplete} from 'manager'

const props = defineProps({
    id: String,
})

const emit = defineEmits(['input'])

const page = reactive({
    admin: false,
    saving: null,
    rules: [],
    user: {},
})

onMounted(async () => {
    if (props.id == null) {
        page.user = {roles: 'user'}
        page.admin = false
    } else {
        let user = (page.user = await User.get({id: props.id}))
        user.admin = State.config.roles.indexOf('admin') >= 0
        user.password = '         '
    }
    await disableAutoComplete()
})

async function save() {
    let user = page.user
    if (user.admin) {
        user.roles = State.config.roles.indexOf('admin') >= 0 ? user.roles : 'admin,user'
    } else {
        user.roles = 'user'
    }
    if (user.id) {
        await User.update(user)
    } else {
        await User.create(user)
    }
    emit('input')
}

async function remove() {
    if (!(await confirm.ask(`Do you want to remove user ${page.user.username}"?`, 'Delete User'))) {
        return
    }
    await User.remove({id: page.user.id})
    Feedback.info('User removed')('input')
}
</script>

<template>
    <vu-form :data="page" autocomplete="off" :title="`${id ? 'Modify' : 'Add'} User`" :save="save">
        <vu-input v-model="page.user.username" name="username" autocomplete="off" :rules="page.rules.name" cols="12" />
        <vu-input v-model="page.user.password" name="password" type="password" autocomplete="off" cols="12" />
        <vu-input v-model="page.user.admin" name="administrator" type="switch" cols="12" />
        <v-row class="actions">
            <v-btn small color="accent" type="submit" :loading="page.saving">Save</v-btn>
            <v-btn small @click="emit('input')">Cancel</v-btn>
            <v-btn small color="error" @click="remove">Delete</v-btn>
        </v-row>
    </vu-form>
</template>

<style lang="scss" scoped>
h1 {
    padding-bottom: 10px;
}
.v-btn {
    margin-right: 10px;
}
</style>
