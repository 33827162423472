<script setup>
/*
    Add or remove a port from a VLAN
*/
import {Port, Vlan} from '@/models'
import {onMounted, reactive} from 'vue'

const props = defineProps({
    id: String,
    portId: String,
})

const emit = defineEmits(['input'])

const page = reactive({
    portId: null,
    ports: [],
    saving: null,
    vlan: null,
})

onMounted(async () => {
    page.vlan = await Vlan.get({id: props.id})
    page.ports = await Port.find()
    page.portId = props.portId
})

async function save() {
    // let port = Port.get({name: `tty${page.port}`})
    await Vlan.addPort({id: page.vlan.id, portId: page.portId})
    emit('input')
}

async function remove() {
    await Vlan.removePort({id: page.vlan.id, portId: page.portId})
    emit('input')
}
</script>

<template>
    <vu-form :data="page" autocomplete="off" title="Add Port to VLAN" :save="save">
        <v-select
            v-model="page.portId"
            dense
            item-title="name"
            item-value="id"
            name="port"
            label="Select Port"
            :items="page.ports" 
            />

        <div class="actions">
            <v-btn small color="accent" type="submit" :loading="page.saving">Add</v-btn>
            <v-btn small @click="emit('input')">Cancel</v-btn>
            <v-btn small color="error" @click="remove">Remove Port</v-btn>
        </div>
    </vu-form>
</template>
