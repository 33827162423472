<script setup>
/*
    Edit a port properties
*/
import {onMounted, reactive, ref} from 'vue'
import {Port, Vlan} from '@/models'
import PortVlan from './PortVlan.vue'

const Options = {
    speed: [1000, 10000, 40000],
    status: {
        online: true,
        offline: false,
    },
    duplex: {
        full: true,
        half: false,
    },
    negotiate: {
        true: true,
        false: false,
    },
    flowControl: {
        true: true,
        false: false,
    },
    jumbo: {
        true: true,
        false: false,
    },
}
const MappingFields = [
    {name: 'name'},
    {name: 'description'},
    {name: 'status', icon: (v) => getStatus(v), css: (v) => `type-${v}`},
]

const props = defineProps({
    id: String,
})

const emit = defineEmits(['input'])

const page = reactive({
    auto: null,
    mappings: null,
    port: {},
    rules: [],
    saving: null,
    showVlan: false,
    vlan: {},
})

//  Component References
const mappingsTable = ref(null)

onMounted(async () => {
    page.port = await Port.get({id: props.id})
    await getMappings()
})

async function getMappings() {
    page.mappings = await Port.mappings({id: props.id})
    if (mappingsTable.value) {
        await mappingsTable.value.refresh()
    }
}

async function save() {
    await Port.update(page.port)
    emit('input')
}

async function clicked({action, column, item, items}) {
    if (action == 'cell' || action == 'edit') {
        editVlan(item)
    } else if (action == 'add') {
        editVlan({})
    } else if (action == 'delete') {
        for (let item of items) {
            await Vlan.removePort({id: item.id, portId: page.port.id})
        }
        await getMappings()
    }
}

async function editVlan(vlan) {
    page.showVlan = vlan ? true : false
    page.vlan = vlan || {}
    await getMappings()
}

function getStatus(status) {
    switch (status) {
        case 'online':
            return '$online'
        case 'offline':
            return '$offline'
    }
}
</script>

<template>
    <vu-form :data="page" :title="`${id ? 'Modify' : 'Add'} Port`" :save="save">
        <vu-input v-model="page.port.name" name="portname" :rules="page.rules.name" type="label" cols="4" />
        <vu-input v-model="page.port.status" name="status" type="label" cols="4" />
        <vu-input v-model="page.port.speed" name="speed" :items="Options.speed" type="select" cols="4" />

        <vu-input v-model="page.port.status" name="Online" :items="Options.status" type="switch" cols="2" />
        <vu-input
            v-model="page.port.negotiate"
            name="autoNegotiate"
            :items="Options.negotiate"
            type="switch"
            cols="2" />
        <vu-input
            v-model="page.port.flowControl"
            name="flowControl"
            :items="Options.flowControl"
            type="switch"
            cols="2" />
        <vu-input v-model="page.port.jumbo" name="jumbo" :items="Options.jumbo" type="switch" cols="2" />
        <vu-input v-model="page.port.duplex" name="fullDuplex" :items="Options.duplex" type="switch" cols="2" />

        <vu-table
            v-if="page.mappings"
            class="mt-3 vcol-12"
            name="port-mappings"
            nodata="No Mappings"
            ref="mappingsTable"
            sort="name:asc"
            title="Port Mappings"
            :data="page.mappings"
            :fields="MappingFields"
            :select="{actions: {Add: 0, Edit: 1, Delete: 2}, multi: true, property: 'select'}"
            @click="clicked" />

        <div class="actions">
            <v-btn small color="accent" type="submit" :loading="page.saving">Save</v-btn>
            <v-btn small @click="emit('input')">Cancel</v-btn>
            <v-btn small dark color="teal" @click="editVlan({})">Add to VLAN</v-btn>
        </div>

        <vu-panel v-model="page.showVlan" @close="editVlan" :widths="['600px']">
            <PortVlan :id="page.port.id" :vlanId="page.vlan.id" @input="editVlan" />
        </vu-panel>
    </vu-form>
</template>

<style lang="scss">
.port-mappings .table-title {
    font-size: 20px !important;
}
</style>
