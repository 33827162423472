/*
    main.js -- Cloud Kickstart main
 */

//  Import the manager class, Vuetify helper and Manager CSS
import {createManager, vuetifyProps} from 'manager'
import 'manager/manager.css'
import 'manager/extras.css'

//  Import the GraphWidget and AWS Cognito authenticator from the managers extras
import {Cognito, GraphWidget} from 'manager/extras'

//  Import core Vue, VueRouter and Vuetity components
import {createApp} from 'vue'
import {createRouter, createWebHashHistory} from 'vue-router'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

//  Import App REST models from kickstart/ui/src
import * as Models from '@/models/index.js'

//  Import App configuration 
import Schema from '../../../../config/schema.json5'
import Display from '../../config/display.json5'
import Build from '../../config/build.json5'
import Ioto from '../../config/ioto.json5'

//  Backend configuration provided by the cloud service
import Connect from '../Connect.js'

//  Import App VueJS components
import Main from './Main.vue'
import Settings from '@/views/Settings/Settings.vue'
import SettingsReset from '@/views/Settings/SettingsReset.vue'
import SettingsDemo from '@/views/Settings/SettingsDemo.vue'
import VlanEdit from '@/views/Vlans/VlanEdit.vue'
import VlanList from '@/views/Vlans/VlanList.vue'
import Vlans from '@/views/Vlans/Vlans.vue'
import VlanPort from '@/views/Vlans/VlanPort.vue'
import AdminModelList from '@/views/Admin/AdminModelList.vue'
import AdminModel from '@/views/Admin/AdminModel.vue'
import UserEdit from '@/views/Users/UserEdit.vue'
import Users from '@/views/Users/Users.vue'
import UserList from '@/views/Users/UserList.vue'
import EventEdit from '@/views/Events/EventEdit.vue'
import Events from '@/views/Events/Events.vue'
import EventList from '@/views/Events/EventList.vue'
import PortList from '@/views/Ports/PortList.vue'
import Ports from '@/views/Ports/Ports.vue'
import PortVlan from '@/views/Ports/PortVlan.vue'
import PortEdit from '@/views/Ports/PortEdit.vue'

//  Import additional MaterialDesign icons
import {mdiFormatListBulletedSquare} from '@mdi/js'
const Icons = {
    events: mdiFormatListBulletedSquare,
}

async function main() {
    /*
        Construct VueJS, Vue Router and Vuetify UI
     */
    let vue = createApp(Main)
    let router = createRouter({
        history: createWebHashHistory(),
        routes: [],
    })
    let vuetify = createVuetify(
        vuetifyProps({
            components: components,
            directives: directives,
            icons: Icons,
            themes: Display.theme.themes,
        })
    )
    vue.use(vuetify)
    let profile = process.env.PROFILE || Ioto.profile
    let connect = Connect[profile] || Connect
    let logo = Connect.logo || Display.theme.logo

    await createManager({
        components: {                       //  App VueJS component
            AdminModel, AdminModelList,
            EventEdit, Events, EventList,
            PortEdit, PortList, PortVlan, Ports,
            Settings, SettingsReset, SettingsDemo,
            VlanEdit, VlanList, Vlans, VlanPort,
            UserEdit, Users, UserList,
        },
        display: Display,                       //  UI Display definition
        schema: Schema,                         //  Database schema
        models: Models,                         //  REST models
        widgets: {graph: GraphWidget},          //  Dashboard widgets
        cognito: Cognito,                       //  AWS Cognito authenticator
        router,                                 //  VueJS router
        vue,                                    //  Vue instance
        vuetify,                                //  Vuetify instance
    }, {
        api: Connect.api,                       //  Backend cloud service API
        cognito: Connect.cognito,               //  Cognito config
        build: Build.build,                     //  App build number
        host: connect.host,                     //  Optional override hostname
        logo: logo,
        profile: profile,                       //  Execution profile (prod | dev)
        name: Display.name,                     //  App name
        title: connect.title || Display.title,  //  App display title
        tunnel: 'generic',                      //  Tunnel requests via 'generic' controller
        version: Display.version,               //  App version (x.y.z)
    })

    /*
        Start VueJS and authenticate the user
     */
    vue.use(router)
    vue.mount('#main')
    router.push({path: '/auth/login'})
}

main()