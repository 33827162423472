<script setup>
/*
    Display a list of VLANs
*/
import {Mapping, Vlan} from '@/models'
import VlanEdit from './VlanEdit.vue'
import {onMounted, reactive, ref} from 'vue'

const Fields = [
    {name: 'edit', icon: '$edit'},
    {name: 'name', width: '5%'},
    {name: 'status', icon: {
        online: {name: '$online', color: 'green'},
        offline: '$offline'
    }},
    {name: 'description'},
    {name: 'ports'},
    {name: 'rxBytes', align: 'right'},
    {name: 'rxPackets', align: 'right'},
    {name: 'txBytes', align: 'right'},
    {name: 'txPackets', align: 'right'},
]
const page = reactive({
    showVlanEdit: false,
    vlan: {},
    vlans: null,
})

onMounted(async () => {
    await renderVlans()
})

async function renderVlans() {
    let vlans = await Vlan.find()
    let mappings = await Mapping.find()
    for (let vlan of vlans) {
        let total = 0
        let count = mappings.reduce((total, map) => {
            return map.vlanId == vlan.id ? total + 1 : total
        }, 0)
        vlan.ports = count
    }
    page.vlans = vlans
}

async function clicked(params) {
    let {action, item, items} = params

    if (action == 'cell' || action == 'edit') {
        editVlan(item)
    } else if (action == 'add') {
        editVlan({})
    } else if (action == 'delete') {
        for (let rec of items) {
            await Vlan.remove({id: rec.id})
        }
        await renderVlans()
    }
}

async function editVlan(vlan) {
    page.showVlanEdit = vlan ? true : false
    page.vlan = vlan || {}
    page.vlan.rxBytes = 42
    await renderVlans()
}
</script>

<template>
    <div class="page vlan-list">
        <vu-table
            name="vlan-table"
            title="VLANs"
            options="filter, toolbar"
            sort="name:asc"
            :data="page.vlans"
            :fields="Fields"
            :select="{actions: {Add: 0, Edit: 1, Delete: 2}, multi: true, property: 'select'}"
            @click="clicked">
            <template v-slot:more="props">
                <v-btn color="accent" @click="editVlan({})">Add VLAN</v-btn>
            </template>
        </vu-table>
        <vu-panel v-model="page.showVlanEdit" @close="editVlan">
            <VlanEdit :id="page.vlan.id" @input="editVlan" />
        </vu-panel>
    </div>
</template>

<style lang="scss">
.port-list {
    td {
        .v-icon {
            font-size: 16px !important;
        }
        &.type-online .v-icon {
            color: green;
        }
        &.type-offline .v-icon {
            color: red;
        }
    }
}
</style>
