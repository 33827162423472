/*
    Event.js - Event model
 */
import {Rest, State} from 'manager'

export default new Rest('event', {}, {

    //  Tunnel when used by CloudKick
    tunnel: 'Event',
    context: (body) => {
        if (State.config.name == 'CloudKick') {
            body.deviceId = State.app.context.deviceId
        }
        return body
    }
})
    