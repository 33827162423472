/*
    Vlan.js - Vlan model
 */

import {Rest, State} from 'manager'
import {Port, Mapping} from './index.js'

export default new Rest('vlan', {
   addPort: { invoke: async (fields) => {
        let port = await Port.get({id: fields.portId})
        let mappings = await Mapping.find({portId: port.id})
        for (let map of mappings) {
            if (map.vlanId == fields.vlanId) {
                throw new Error('Mapping already exists')
            }
        }
        fields.vlanId = fields.id
        delete fields.id
        return await Mapping.create(fields)
   }},

   mappings: { invoke: async (fields) => {
        return await Mapping.find({vlanId: fields.id})
   }},

   removePort: { invoke: async (fields) => {
        let port = await Port.get({id: fields.portId})
        let mappings = await Mapping.find({portId: port.id})
        for (let map of mappings) {
            if (map.vlanId == fields.id) {
                await Mapping.remove({id: map.id})
                return;
            }
        }
        throw new Error('Cannot find mapping')
   }},

}, {
    //  Tunnel when used by CloudKick
    tunnel: 'Vlan',
    context: (body) => {
        if (State.config.name == 'CloudKick') {
            body.deviceId = State.app.context.deviceId
        }
        return body
    }
})