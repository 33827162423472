<script setup>
/*
    Edit a VLAN properties
*/
import {onMounted, reactive, ref} from 'vue'
import {Port, Vlan} from '@/models'
import VlanPort from './VlanPort.vue'

const Options = {
    status: {
        online: true,
        offline: false,
    },
}

const props = defineProps({
    id: String,
})

const emit = defineEmits(['input'])

const page = reactive({
    port: {},
    ports: null,
    rules: [],
    saving: null,
    showPort: false,
    vlan: {},
})

//  Component references
const mappingsTable = ref(null)
const confirm = ref(null)

onMounted(async () => {
    if (props.id != null) {
        page.vlan = await Vlan.get({id: props.id})
    }
    await renderMappings()
})

async function renderMappings() {
    let vlan = page.vlan
    let ports = []
    if (vlan && vlan.id) {
        let mappings = await Vlan.mappings({id: vlan.id})
        for (let map of mappings) {
            let port = await Port.get({id: map.portId})
            ports.push(port)
        }
    }
    page.ports = ports
}

async function save() {
    await Vlan.update(page.vlan)
    emit('input')
}

async function remove() {
    if (!(await confirm.ask(`Do you want to remove ${page.vlan.name}?`))) {
        return
    }
    await Vlan.remove({id: page.vlan.id})
    emit('input')
}

async function clicked({action, column, item, items}) {
    if (action == 'add') {
        editPort()
    } else if (action == 'delete') {
        for (let item of items) {
            await Vlan.removePort({id: page.vlan.id, portId: item.id})
        }
        await renderMappings()
        if (mappingsTable.value) {
            await mappingsTable.value.refresh()
        }
    }
}

async function editPort(port) {
    page.showPort = port ? true : false
    page.port = port || {}
    await renderMappings()
}
</script>

<template>
    <vu-form :data="page" autocomplete="off" :title="`${id ? 'Modify' : 'Add'} Vlan`" :save="save">
        <vu-input v-model="page.vlan.name" name="name" :rules="page.rules.name" :disabled="page.vlan.id ? true : false" cols="6" />
        <vu-input flex-grow-2 v-model="page.vlan.description" name="description" type="text" :disabled="page.vlan.id ? true : false" cols="6" />

        <vu-input v-model="page.vlan.rxBytes" name="Rx Bytes" type="label" disabled cols="6" />
        <vu-input v-model="page.vlan.rxPackets" name="Rx Packets" type="label" disabled cols="6" />

        <vu-input v-model="page.vlan.txBytes" name="Tx Bytes" type="label" disabled cols="6" />
        <vu-input v-model="page.vlan.txPackets" name="Tx Packets" type="label" disabled cols="6" />

        <vu-input v-model="page.vlan.status" name="Online" :items="Options.status" type="switch" cols="6" />

        <vu-table
            v-if="page.ports"
            class="vcol-12"
            name="vlan-mappings"
            nodata="No Mappings"
            ref="mappingsTable"
            sort="name:asc"
            title="Port Mappings"
            fields="name, enable, status, speed"
            :data="page.ports"
            :select="{actions: {Add: 0, Delete: 2}, multi: true, property: 'select'}"
            @click="clicked" />

        <div class="actions">
            <v-btn small color="accent" type="submit" :loading="page.saving">Save</v-btn>
            <v-btn small @click="emit('input')">Cancel</v-btn>
            <v-btn small dark color="teal" @click="editPort">Add Port</v-btn>
            <v-btn small color="error" @click="remove">Delete</v-btn>
        </div>

        <vu-panel v-model="page.showPort" @close="editPort" :widths="['600px']">
            <VlanPort :id="page.vlan.id" :portId="page.port.id" @input="editPort" />
        </vu-panel>
    </vu-form>
</template>

<style lang="scss" scoped>
h1 {
    padding-bottom: 10px;
}

.row {
    .vu-input {
        flex-grow: 1;
        flex-basis: 200px;
    }
}
break {
    flex-basis: 100%;
    height: 0;
}
</style>
