<template>
    <div class="logging">
        <vu-nav title="Events">
            <v-tab to="/events/list">List</v-tab>
        </vu-nav>
        <v-container>
            <router-view />
        </v-container>
    </div>
</template>
